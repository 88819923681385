<template>
  <div
    v-if="lesson"
    class="page-lesson"
    :data-class-id="lesson.class"
  >
    <div
      class="page-header"
      :data-class-id="lesson.class"
    >
      <router-link to="/ccmp-pro/tasks" class="page-header__link-back">
        <i class="material-icons">keyboard_arrow_left</i>
      </router-link>
      <div class="page-header__info">
        <span class="page-header__breadcrumbs">{{ ccmpProTitle }}</span>
        <span class="page-header__title">{{ lesson.title }}</span>
      </div>
    </div>

    <div class="table-container">
      <vue-markdown-it class="purpose markdown-container" :source="lesson.purpose" />

      <table v-if="lesson.templates && lesson.templates.length > 5" class="table-objectives">
        <thead>
          <tr>
            <th class="no-transform">jTask Templates</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="no-padding">
              <vue-markdown-it class="markdown-container" :source="lesson.templates" />
            </td>
          </tr>
        </tbody>
      </table>

      <table class="table-objectives">
        <thead>
          <tr>
            <th>#</th>

            <th v-if="ccmpProTitle === 'Knowledge Base'">Question</th>
            <th v-else>Task</th>

            <th></th>
          </tr>
        </thead>

        <tbody
          v-for="objective in objectives" :key="objective.id">
          <tr class="row-objective" @click="onObjectiveClick(objective)">
            <td>
              <span class="objective">{{ formatTaskId(objective.id) }}</span>
            </td>
            <td>
              <span class="objective">{{ objective.name }}</span>
            </td>
            <td class="objective-link">
              <i class="material-icons">keyboard_arrow_right</i>
            </td>
          </tr>

          <!-- Process sub steps -->
          <tr
            v-for="processSubStep in objective.processSubSteps"
            :key="processSubStep.id"
            class="row-sub-objective"
            @click="onObjectiveClick(processSubStep)">
            <td></td>
            <td>
              <span class="objective">{{ formatTaskId(processSubStep.id) }} &nbsp; &nbsp;{{ processSubStep.name }}</span>
            </td>
            <td class="objective-link">
              <i class="material-icons">keyboard_arrow_right</i>
            </td>
          </tr>
        </tbody>

      </table>
    </div>
  </div>

</template>

<script>
import VueMarkdownIt from 'vue3-markdown-it'

export default {
  name: 'CcmpProTask',

  props: ['id'],

  components: {
    VueMarkdownIt
  },

  computed: {
    lesson () {
      return this.$store.state.ccmpData.lessons.find((lesson) => {
        return lesson.id === this.id
      })
    },

    objectives () {
      return this.$store.state.ccmpData.processSteps.filter((processStep) => {
        return processStep.processGroup === this.lesson.id
      })
    },

    ccmpProTitle () {
      return this.$store.state.ccmpData.ccmpProTitles[this.lesson.id]
    }
  },

  methods: {
    onObjectiveClick (objective) {
      this.$router.push({ name: 'ccmp-pro-objective', params: { id: objective.id } })
    },

    formatTopicId (id) {
      return id
    },

    formatTaskId (id) {
      if (id.length === 1) {
        return id
      }
      if (id.length === 3) {
        return id.substr(id.length - 1)
      }
      if (id.length === 4) {
        return id.substr(id.length - 2)
      }
      if (id.length === 5) {
        return id.substr(id.length - 1)
      }
      if (id.length === 6) {
        return id.substr(id.length - 2)
      }
      if (id.length === 7) {
        return id.substr(id.length - 3)
      }
      if (id.length === 8) {
        return id.substr(id.length - 4)
      }
    },

    formatRichText (text) {
      return text.replace(/\n/g, '<br />')
    }
  }

}
</script>

<style lang="scss" scoped>
  @import '@/styles/_variables.scss';

  .page-lesson {
    height: 100%;

    &[data-class-id="1"] {
      background: $orange-light;

      .table-objectives {
        th {
          border-bottom: 2px solid $orange-mid;
        }

        .row-objective, .row-sub-objective {
          border-bottom: 1px solid $orange-mid;
        }

        .row-objective:hover, .row-sub-objective:hover {
          background: $orange-hover;
        }
      }
    }

    &[data-class-id="2"] {
      background: $jtask-blue-light;

      .table-objectives {
        th {
          border-bottom: 2px solid $jtask-blue-mid;
        }

        .row-objective, .row-sub-objective {
          border-bottom: 1px solid $jtask-blue-mid;
        }

        .row-objective:hover, .row-sub-objective:hover {
          background: $jtask-blue-hover;
        }
      }
    }

    &[data-class-id="3"] {
      background: $blue-light;

      .table-objectives {
        th {
          border-bottom: 2px solid $blue-mid;
        }

        .row-objective, .row-sub-objective {
          border-bottom: 1px solid $blue-mid;
        }

        .row-objective:hover, .row-sub-objective:hover {
          background: $blue-hover;
        }
      }
    }

    &[data-class-id="4"] {
      background: $green-light;

      th {
        border-bottom: 2px solid $green-mid;
      }

      .table-objectives {
        .row-objective, .row-sub-objective {
          border-bottom: 1px solid $green-mid;
        }

        .row-objective:hover, .row-sub-objective:hover {
          background: $green-hover;
        }
      }
    }
  }

  .page-header {
    display: flex;
    flex-direction: row;
  }

  .page-header__link-back {
    flex: 0 0 45px;
    color: #fff;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }

  .page-header__info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #FFFFFF;
    padding: 15px 15px 15px 0;
    overflow: hidden;
  }

  .page-header__breadcrumbs {
    font-size: 14px;
    line-height: 1.4;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 3px;
  }

  .page-header__title {
    font-size: 15px;
    line-height: 1.4;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  .table-container {
    // height: calc(100% - 136px);
    height: calc(100% - 85px);
    overflow-y: auto;
    padding: 5px;
  }

  .table-objectives {
    width: 100%;
    padding: 20px 10px 10px 10px;
    border-collapse: collapse;

    tbody tr {
      cursor: pointer;
    }

    tr.row-objective td {
      // padding-top: 3px;
    }

    th {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
      text-align: left;
      padding: 8px 8px 10px 8px;

      &:nth-child(1) {
        width: 4%;
      }

      &:nth-child(2) {
        width: 80%;
      }

      &:nth-child(3) {
        width: 5%;
      }

      &:nth-child(4) {
        width: 3%;
      }

      &.no-transform {
        text-transform: none;
      }
    }

    td {
      font-size: 14px;
      line-height: 1.4;
      vertical-align: top;
      padding: 10px;

      &.no-padding {
        padding: 0 10px;
      }
    }

    .objective {
      display: inline-block;
      width: 100%;
    }

    .eco-list {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .objective-link {
      vertical-align: middle;
      padding: 0;
    }
  }

  .purpose, .templates {
    padding: 0 10px;
    line-height: 1.4;
  }

  .button-link {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    color: #FFFFFF;
    width: 100%;
    text-transform: uppercase;
    padding: 15px 20px;

    i {
      color: #fff;
      margin-right: 10px;
    }

    .icon-right {
      position: absolute;
      right: -5px;
    }

  }

</style>
